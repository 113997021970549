.serviceBlock{
  padding: 2rem;
  border-radius: 20px;


  -moz-transition: all .5s;
  -ms-transition: all .5s;
  -o-transition: all .5s;
  -webkit-transition: all .5s;
  transition: all .5s;

  height: 300px;
  background-color: transparent;
  color:#333333;
  background-image: linear-gradient(transparent, transparent)
}
.serviceBlock:hover{

}

.serviceAimation{
  height: 500px;
  background-image: linear-gradient(#FF8200, transparent);
  color:white;
  animation: fade-in-keyframes 1s;
  transition: 1s ;
}

@keyframes fade-in-keyframes {
  from {margin-bottom: 0}
  to {margin-bottom: 20}
}

.serviceDetails{
  opacity: 100%;
  transition: 1s;
}