::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 1rem; // horizontal
  width: 1rem;  // vertical
}

// inset a box shadow to use `border`
::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  box-shadow: inset 0 0 1rem 1rem rgba(97, 96, 96, 0.5);
  // above lg devices (desktop) larger scrollbar-thumb to make it easier to click
  border: solid 0.3rem transparent;

  // Below lg devices
  @media only screen and (max-width: 992px) {
     // smaller border, larger scrollbar-thumb
    border: solid 0.4rem transparent;
  }
}

// No bg for scrollbars on top of colored backgrounds
::-webkit-scrollbar-track {
  background-color: transparent;
}
