body {
  margin: 0;
  font-family: "Montserrat" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Montserrat" !important;
}

.h1 {
  font-size: 48px !important;
  font-weight: bolder !important;
}

.text-subtitle {
  font-size: 20px !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 1rem;
  width: 1rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  box-shadow: inset 0 0 1rem 1rem rgba(97, 96, 96, 0.5);
  border: solid 0.3rem transparent;

  @media only screen and (max-width: 992px) {
    border: solid 0.4rem transparent;
  }
}
